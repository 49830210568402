<div class="flex flex-auto">
  <mat-sidenav-container class="flex flex-auto">
    <mat-sidenav
      #sidenav
      [mode]="(this.isMobile$ | async) ? 'over' : 'side'"
      [opened]="!(this.isMobile$ | async)"
      [disableClose]="!(this.isMobile$ | async)"
      class="shell-main"
    >
      <div class="shell-wrapper flex flex-col" *ngIf="this.workspace$ | async as ws">
        <mat-toolbar class="header-wrapper mat-toolbar-tg" *ngIf="!this.isTeams">
          <button
            mat-icon-button
            matRipple
            [matRippleColor]="'rgba(255,255,255,.02)'"
            class="mat-icon-button-alt mat-icon-fluent"
            [satPopoverAnchor]="officeMenu"
            (click)="officeMenu.toggle()"
          >
            <ms-icon icon="WaffleOffice365"></ms-icon>
          </button>
          <div
            class="header-image flex cursor-pointer flex-row items-center justify-start space-x-2"
            [routerLink]="'/'"
          >
            <div class="h-10">
              <mat-icon class="header-logo" svgIcon="logo" inline></mat-icon>
            </div>
          </div>
        </mat-toolbar>
        <div class="shell-inner flex flex-auto flex-col">
          <nav>
            <!-- Timer -->
            <mat-list class="my-3 flex flex-col !space-y-2">
              <a
                mat-list-item
                matRipple
                class="mat-primary shell-list-item route-icon-timer flex flex-row wflex-[1_0_auto]"
                routerLinkActive="active"
                routerLink="/timer"
              >
                <div class="route-item">
                  <mat-icon svgIcon="ftimer"></mat-icon>
                  <ng-container *ngIf="{ entity: this.runningTime$ | async } as time">
                    <ng-container
                      *ngTemplateOutlet="
                        time.entity && time.entity.start && !time.entity.end ? showTimer : showTimerText;
                        context: { $implicit: { time: time.entity } }
                      "
                    >
                    </ng-container>
                  </ng-container>
                </div>
              </a>
              <!-- Feed -->
              <a
                mat-list-item
                matRipple
                class="mat-primary shell-list-item route-icon-calendar flex flex-row wflex-[1_0_auto]"
                routerLinkActive="active"
                routerLink="/feed"
                *ngIf="!(comegoOnly$ | async)"
              >
                <div class="route-item">
                  <mat-icon svgIcon="fcalendar"></mat-icon>
                  <span class="ml-2" translate>feed.name</span>
                </div>
              </a>
            </mat-list>
            <div class="mat-subheader select-none text-sm font-bold" translate>insights.name</div>
            <mat-list class="mb-3 flex flex-col !space-y-2">
              @if (dashboardViewable$ | async; as dashboardViewable) {
                <!-- Dashboard -->
                <a
                  mat-list-item
                  matRipple
                  class="mat-primary shell-list-item route-icon-dashboard flex flex-row wflex-[1_0_auto]"
                  routerLinkActive="active"
                  [routerLink]="dashboardViewable.comegoOnly ? '/dashboard/schedules' : '/dashboard'"
                >
                  <div class="route-item">
                    <mat-icon svgIcon="fdashboard"></mat-icon>
                    <span class="ml-2" translate>dashboard.name</span>
                  </div>
                </a>
              }
              <!-- Team-Activity -->
              <a
                mat-list-item
                matRipple
                class="mat-primary shell-list-item route-icon-tactivity flex flex-row wflex-[1_0_auto]"
                routerLinkActive="active"
                routerLink="/team-activity"
                *ngIf="ws.permissions.groupsCanSeeTeamActivity"
              >
                <div class="route-item">
                  <mat-icon svgIcon="factivity"></mat-icon>
                  <span class="ml-2" translate>team-activity.name</span>
                </div>
              </a>
              <!-- Insights -->
              <a
                mat-list-item
                matRipple
                class="mat-primary shell-list-item route-icon-insights flex flex-row wflex-[1_0_auto]"
                routerLinkActive="active"
                routerLink="/profitability"
                *ngIf="ws.permissions.groupsCanSeeBillableRates && !(this.comegoOnly$ | async)"
              >
                <div class="route-item">
                  <mat-icon svgIcon="finsights"></mat-icon>
                  <span class="ml-2" translate>insights.profitability</span>
                </div>
              </a>
              <!-- Supervisor -->
              <a
                mat-list-item
                matRipple
                class="mat-primary shell-list-item route-icon-supervise flex flex-row wflex-[1_0_auto]"
                routerLinkActive="active"
                routerLink="/supervise"
                *ngIf="isSupervisor$ | async"
              >
                <div class="route-item">
                  <mat-icon svgIcon="fsupervisor"></mat-icon>
                  <span class="ml-2" translate>supervisor.name</span>
                </div>
              </a>
              <a
                mat-list-item
                matRipple
                class="mat-primary shell-list-item route-icon-tactivity flex flex-row wflex-[1_0_auto]"
                routerLinkActive="active"
                routerLink="/testing"
                *ngIf="!production"
              >
                <div class="route-item">
                  <span>Testing Page</span>
                </div>
              </a>
            </mat-list>
            <div
              class="mat-subheader select-none text-sm font-bold"
              translate
              *ngIf="!(comegoOnly$ | async) || ((comegoOnly$ | async) && (isWorkspaceOwner$ | async))"
            >
              workspace.single
            </div>
            <mat-list class="flex flex-col !space-y-2">
              <a
                mat-list-item
                matRipple
                class="mat-primary shell-list-item flex flex-row wflex-[1_0_auto]"
                routerLinkActive="active"
                routerLink="/settings/workspace/team"
                *ngIf="this.workspace$admin | async"
              >
                <div class="route-item">
                  <mat-icon mat-inline>group</mat-icon>
                  <span class="ml-2">{{ 'team._' | translate }}</span>
                </div>
              </a>
              <a
                mat-list-item
                matRipple
                class="mat-primary shell-list-item flex flex-row wflex-[1_0_auto]"
                routerLinkActive="active"
                routerLink="/settings/projects"
                *ngIf="!(comegoOnly$ | async)"
              >
                <div class="route-item">
                  <mat-icon>folder</mat-icon>
                  <span class="ml-2" translate>project.plural</span>
                </div>
              </a>
              <a
                mat-list-item
                matRipple
                class="mat-primary shell-list-item flex flex-row wflex-[1_0_auto]"
                routerLinkActive="active"
                routerLink="/settings/clients"
                *ngIf="!(comegoOnly$ | async)"
              >
                <div class="route-item">
                  <mat-icon>account_tree</mat-icon>
                  <span class="ml-2" translate>client.plural</span>
                </div>
              </a>

              <a
                mat-list-item
                matRipple
                class="mat-primary shell-list-item flex flex-row wflex-[1_0_auto]"
                routerLinkActive="active"
                routerLink="/settings/tags"
                [teamsHideMobile]="true"
                *ngIf="!(comegoOnly$ | async)"
              >
                <div class="route-item">
                  <mat-icon>label</mat-icon>
                  <span class="ml-2" translate>tag.plural</span>
                </div>
              </a>
              <ng-container *ngIf="this.workspace$admin | async">
                <a
                  routerLink="/settings/workspace/"
                  [routerLinkActiveOptions]="{ exact: true }"
                  routerLinkActive="active"
                  mat-list-item
                  matRipple
                  class="mat-primary shell-list-item flex flex-row wflex-[1_0_auto]"
                  teamsHideMobile
                >
                  <div class="route-item">
                    <mat-icon mat-inline>settings</mat-icon>
                    <div class="ml-2 flex flex-col" translate>
                      <span>{{ 'workspace.settings' | translate }}</span>
                    </div>
                  </div>
                </a>
              </ng-container>
              <ng-container *teams-hide teamsHideMobile>
                <ng-container *ngIf="workspaceStatus$ | async as status">
                  <a
                    mat-list-item
                    matRipple
                    class="mat-primary shell-list-item flex flex-row wflex-[1_0_auto]"
                    routerLinkActive="active"
                    routerLink="/settings/workspace/subscriptions"
                    *ngIf="status.subscription && status.isOwner && !status.isTrial && !status.isChargebee"
                  >
                    <div class="route-item">
                      <mat-icon>card_membership</mat-icon>
                      <span class="ml-2">{{ 'subscription.single' | translate }}</span>
                    </div>
                  </a>
                </ng-container>
              </ng-container>

              <a
                mat-list-item
                matRipple
                class="mat-primary shell-list-item route-icon-flow flex flex-row wflex-[1_0_auto]"
                routerLinkActive="active"
                routerLink="/automation"
                *ngIf="!(comegoOnly$ | async)"
              >
                <div class="route-item">
                  <mat-icon>auto_fix_high</mat-icon>
                  <span class="ml-2" translate>automation.name</span>
                </div>
              </a>
            </mat-list>
          </nav>
          <div class="flex-auto"></div>
          <mat-list
            *ngIf="{
              isTrial: this.userSettings.workspace.subscription.status != 'active',
              user: (user$ | async),
            } as wsRef"
            class="mb-3 mt-1.5 flex flex-col space-y-2"
          >
            <a
              *ngIf="wsRef.isTrial && wsRef.user as user"
              mat-list-item
              matRipple
              class="mat-primary shell-list-item flex flex-row wflex-[1_0_auto]"
              [href]="
                ('livedemo-url' | translate) +
                  '?email=' +
                  user.email +
                  '&name=' +
                  (user.officeProfile?.displayName ? user.officeProfile?.displayName : user.email) | safeUrl
              "
              target="_blank"
              rel="external noopener nofollow"
            >
              <div class="route-item">
                <mat-icon>people_alt</mat-icon>
                <span class="ml-2" translate>livedemo</span>
              </div>
            </a>
            <a
              *ngIf="wsRef.user?.links?.partner as partnerLink"
              mat-list-item
              matRipple
              class="mat-primary shell-list-item flex flex-row wflex-[1_0_auto]"
              [href]="partnerLink"
              target="_blank"
              rel="external noopener nofollow"
            >
              <div class="route-item">
                <mat-icon>add_business</mat-icon>
                <span class="ml-2" translate>partners.request</span>
              </div>
            </a>
          </mat-list>
          <div class="mat-bg-3 mx-2 flex flex-col rounded">
            <div
              gdArea
              gdGap="12px"
              gdColumns="1fr 1fr 1fr 1fr"
              gdAlignColumns="center stretch"
              gdAlignRows="center center"
              class="m-2"
            >
              <a
                mat-icon-button
                class="mat-icon-button-flex"
                matTooltip="Teams App"
                [href]="meta.teams"
                target="_blank"
                teams-hide
              >
                <mat-icon svgIcon="ms_teams"></mat-icon>
              </a>
              <a
                mat-icon-button
                class="mat-icon-button-flex"
                matTooltip="Youtube"
                [href]="meta.youtube"
                target="_blank"
              >
                <mat-icon svgIcon="youtube"></mat-icon>
              </a>
              <a mat-icon-button class="mat-icon-button-flex" matTooltip="API" [href]="meta.apiDocs" target="_blank">
                <mat-icon>settings_ethernet</mat-icon>
              </a>
              <button
                mat-icon-button
                class="mat-icon-button-flex"
                (click)="openWidget()"
                matTooltip="Support"
                [disabled]="widgetLoading"
              >
                <mat-icon *ngIf="!widgetLoading; else widgetLoadingRef">live_help</mat-icon>
              </button>
            </div>
          </div>
          <div class="pb-2"></div>
          <div class="flex flex-col">
            <div class="flex flex-col space-y-2">
              <div
                class="line-height-base mat-bg-2-hover mx-2 mb-2 flex cursor-pointer select-none flex-row items-center justify-start gap-2 rounded px-4 py-3"
                (click)="openWorkspaceDialog()"
              >
                <mat-icon class="mat-bg-5 rounded-circle -ml-2 p-2 text-lg" inline>work</mat-icon>
                <div class="flex flex-auto flex-col truncate">
                  <span class="text-sm" translate>workspace.single</span>
                  <div class="truncate text-sm font-bold">{{ ws.name }}</div>
                </div>
                <mat-icon class="mat-bg-5 -mr-1 !rounded-xl text-lg !leading-normal" inline>unfold_more</mat-icon>
              </div>
            </div>
            <ng-container *ngIf="this.workspaceStatus$ | async as status">
              <ng-container *ngIf="showUser$ | async">
                <mat-divider inset></mat-divider>
                <div class="mt-2 flex items-center space-x-2 pr-2 overflow-hidden">
                  <div
                    class="mat-bg-2-hover mx-2 mb-2 flex flex-auto cursor-pointer select-none flex-row items-center justify-start gap-[10px] truncate rounded px-4 py-2"
                    (click)="userMenuRef.menuTrigger.toggleMenu()"
                  >
                    <app-user-menu
                      #userMenuRef
                      class="-ml-2"
                      [disableMenu]="true"
                      [closeOnNavigation]="true"
                      size="30px"
                      (click)="$event.stopPropagation(); $event.preventDefault()"
                    ></app-user-menu>
                    <div class="flex flex-col truncate flex-auto">
                      <span class="mat-typography -mr-2 truncate text-sm">{{
                        this.currentUser.officeProfile.displayName | parseName
                      }}</span>
                    </div>
                  </div>
                  <tg-frill-button></tg-frill-button>
                </div>
              </ng-container>
              <div class="flex flex-col space-y-2" teams-hide teamsHideMobile>
                <ng-container *ngIf="status.isTrial; else nonTrialExpired">
                  <div
                    class="flex flex-col justify-center"
                    [class.cursor-pointer]="!status.isTeamsMobile"
                    [send-tagmanager_event]="{
                      category: 'purchase_c2a',
                      action: 'upgrade button sidebar',
                    }"
                    (click)="!status.isTeamsMobile && this.openWorkspacePlan()"
                    *ngIf="!status.isTeamsMobile"
                  >
                    <ng-container *ngIf="!status.isExpired; else nonTrialExpired">
                      <div class="alert alert-primary text-sm" *ngIf="status.endDate as end">
                        Trial, {{ end | dfnsFormatDistanceToNow }}
                      </div>
                    </ng-container>
                  </div>
                </ng-container>
                <ng-template #nonTrialExpired>
                  <tg-subscription-status-row [navigateOnClick]="true"></tg-subscription-status-row>
                </ng-template>
              </div>
            </ng-container>
          </div>
        </div>
      </div>
    </mat-sidenav>
    <mat-sidenav-content #container class="flex flex-auto">
      <div id="wrapper" class="router-wrapper">
        <router-outlet #o="outlet"></router-outlet>
        <app-loader #routerLoader [enableCircle]="true" [isActive]="this.isLoading !== false"></app-loader>
        <ng-container
          *ngTemplateOutlet="
            (view$ | async) === 'work'
              ? RecordProjectWidget
              : (comegoEnabled$ | async)
                ? RecordComegoWidget
                : RecordProjectWidget
          "
        ></ng-container>
      </div>
    </mat-sidenav-content>
  </mat-sidenav-container>
</div>
<ng-template #RecordProjectWidget>
  <tg-record-widget></tg-record-widget>
</ng-template>
<ng-template #RecordComegoWidget>
  <tg-record-comego-widget></tg-record-comego-widget>
</ng-template>
<sat-popover #officeMenu hasBackdrop class="popover-menu-top-left-offset">
  <mat-card class="ms-office-menu ms-office-menu-offset">
    <div class="flex flex-col space-y-2">
      <div class="popover-card-header">
        <div class="flex flex-row items-center justify-start space-x-2 p-2">
          <button mat-icon-button class="mat-icon-button-alt mat-icon-fluent" (click)="officeMenu.close()">
            <ms-icon icon="WaffleOffice365" class="mat-typography"></ms-icon>
          </button>
          <div class="!ml-auto mr-1 flex flex-row items-center justify-start">
            <a href="https://www.office.com" target="_blank" referrerpolicy="origin" mat-link>
              <div class="flex flex-row items-center justify-start gap-[4px]">
                <small>Microsoft 365</small>
                <mat-icon inline>arrow_forward</mat-icon>
              </div>
            </a>
          </div>
        </div>
      </div>
      <h4 class="mt-0">Apps</h4>
      <div class="ms-office-menu-list flex flex-col gap-[4px] p-1">
        <div class="flex flex-row items-center justify-start space-x-2">
          <button
            (click)="openLink('https://www.office.com/launch/word')"
            mat-button
            class="ms-office-menu-item text-left"
          >
            <div class="flex flex-row items-center justify-start gap-[4px]">
              <mat-icon svgIcon="ms_word" class="ms-Icon"></mat-icon>
              <span>Word</span>
            </div>
          </button>
          <button
            (click)="openLink('https://www.office.com/launch/powerpoint')"
            mat-button
            class="ms-office-menu-item text-left"
          >
            <div class="flex flex-row items-center justify-start gap-[4px]">
              <mat-icon svgIcon="ms_powerpoint" class="ms-Icon"></mat-icon>
              <span>Power Point</span>
            </div>
          </button>
        </div>
        <div class="flex flex-row items-center justify-start space-x-2">
          <button
            (click)="openLink('https://outlook.office.com/owa/?modurl=0')"
            mat-button
            class="ms-office-menu-item text-left"
          >
            <div class="flex flex-row items-center justify-start gap-[4px]">
              <mat-icon svgIcon="ms_outlook" class="ms-Icon"></mat-icon>
              <span>Outlook</span>
            </div>
          </button>
          <button (click)="openLink('https://aka.ms/mstfw')" mat-button class="ms-office-menu-item text-left">
            <div class="flex flex-row items-center justify-start gap-[4px]">
              <mat-icon svgIcon="ms_teams" class="ms-Icon"></mat-icon>
              <span>Teams</span>
            </div>
          </button>
        </div>
        <div class="flex flex-row items-center justify-start space-x-2">
          <button
            (click)="openLink('https://www.microsoft.com/microsoft-365/onedrive/onedrive-for-business')"
            mat-button
            class="ms-office-menu-item text-left"
          >
            <div class="flex flex-row items-center justify-start gap-[4px]">
              <mat-icon svgIcon="ms_onedrive" class="ms-Icon"></mat-icon>
              <span>OneDrive</span>
            </div>
          </button>
          <button (click)="openLink('https://to-do.office.com/tasks')" mat-button class="ms-office-menu-item text-left">
            <div class="flex flex-row items-center justify-start gap-[4px]">
              <mat-icon svgIcon="ms_todo" class="ms-Icon"></mat-icon>
              <span>To Do</span>
            </div>
          </button>
        </div>
        <div class="ms-office-menu-footer flex flex-row items-center justify-center">
          <a href="https://timeghost.io" mat-link class="mat-hint py-1" target="_blank">
            <small>timeghost.io</small>
          </a>
        </div>
      </div>
    </div>
  </mat-card>
</sat-popover>
<ng-template #showTimer let-item>
  <span class="ml-2">{{
    item.time.start | dfnsParseIso | dfnsDifferenceInSeconds: now() | parseMsAsDuration: true
  }}</span>
</ng-template>
<ng-template #showTimerText>
  <span class="ml-2" translate>timer.single</span>
</ng-template>
<ng-template #widgetLoadingRef>
  <mat-progress-spinner [strokeWidth]="2" [diameter]="18" mode="indeterminate"> </mat-progress-spinner>
</ng-template>
