<div>
  <app-user-avatar [size]="size" class="rounded-circle cursor-pointer" [matMenuTriggerFor]="menu"></app-user-avatar>
  <mat-menu [overlapTrigger]="true" #menu="matMenu" class="mat-menu-nopad usermenu-panel">
    <div (click)="$event.stopPropagation()" class="flex flex-col items-stretch justify-start">
      <div class="userinfo-section flex flex-col items-center" *ngIf="{ title: userTitle$ | async } as user">
        <a
          href="https://portal.office.com/account/#personalinfo"
          target="_blank"
          class="my-4"
          matTooltip
          [matTooltipEnabled]="!!user.title"
          [matTooltipTemplate]="userTitleTemplate"
          [matTooltipTemplateContext]="{ title: user?.title }"
        >
          <app-user-avatar size="64px" class="rounded-circle mat-elevation-z4 flex-shrink-0"></app-user-avatar>
        </a>
        <div class="flex flex-col items-center justify-center text-center">
          <span>{{ name$ | async }}</span>
          <small class="mat-hint"> {{ this.currentWorkspace.name }} </small>
        </div>
      </div>
      <div class="my-2"><div class="mat-divider"></div></div>
      <mat-list dense class="pt-0 space-y-1 px-2 [&_.mat-list-item]:rounded-lg">
        <a mat-list-item [routerLink]="['/account', 'profile']" mat-ripple class="cursor-pointer">{{
          'account.profile._' | translate
        }}</a>
        <mat-list-item
          (click)="openCurrentWorkspaceCheckout()"
          mat-ripple
          class="cursor-pointer"
          *ngIf="(status$ | async).isExpired"
          >{{ 'user.buy-license' | translate }}</mat-list-item
        >
        <mat-list-item (click)="openFeedback()" mat-ripple class="cursor-pointer">
          {{ 'Feedback' | translate }}</mat-list-item
        >
        <a mat-list-item href="https://portal.office.com/account/#personalinfo" mat-ripple target="_blank">{{
          'user.office.manageprofile' | translate
        }}</a>
        <a mat-list-item teams-hide href="https://web-beta.timeghost.io" mat-ripple target="_blank">{{
          'user.trybeta' | translate
        }}</a>
        <mat-list-item (click)="$event.stopPropagation()" mat-ripple class="cursor-pointer">
          @if (selectedTheme$.state.value$ | async; as theme) {
            <mat-select
              [value]="theme.value"
              (selectionChange)="setTheme($event.value)"
              [disabled]="selectedTheme$.loading.value$ | async"
            >
              <mat-select-trigger>
                <div class="flex items-center space-x-2">
                  <div class="mat-size-4 flex-shrink-0">
                    <mat-icon inline>{{ theme.icon }}</mat-icon>
                  </div>
                  <span class="flex-auto">{{ 'theme.' + theme.value | translate }}</span>
                  @if (selectedTheme$.loading.value$ | async) {
                    <mat-spinner [mode]="'indeterminate'" [strokeWidth]="2" [diameter]="18"></mat-spinner>
                  }
                </div>
              </mat-select-trigger>
              <mat-option value="default">{{ 'theme.system' | translate }}</mat-option>
              <mat-option value="light">{{ 'theme.light' | translate }}</mat-option>
              <mat-option value="dark">{{ 'theme.dark' | translate }}</mat-option>
            </mat-select>
          }
        </mat-list-item>
        <mat-list-item (click)="logout()" mat-ripple class="cursor-pointer"> {{ 'LogOut' | translate }}</mat-list-item>
      </mat-list>
      <div class="mb-0 mt-2"><div class="mat-divider"></div></div>
      <div class="flex flex-col p-2">
        <small class="mat-hint text-center"
          >{{ this.releaseName || this.packageVersion }}
          <span *isBeta class="ml-1">(Beta)</span>
        </small>
      </div>
    </div>
  </mat-menu>
</div>
<ng-template #userTitleTemplate let-title="title">
  <div class="flex items-center space-x-2 font-bold">
    <mat-icon inset>person</mat-icon>
    <div>{{ title }}</div>
  </div>
</ng-template>
