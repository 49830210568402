import { Component, Input, OnInit, Optional, ViewChild } from '@angular/core';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { MatLegacyMenuTrigger as MatMenuTrigger } from '@angular/material/legacy-menu';
import { NavigationExtras, Router } from '@angular/router';
import parseSubscriptionAsStatus from '@app/_helpers/parseSubscriptionAsStatus';
import { fromRxValueWithState } from '@app/_helpers/utils';
import { AppService, ThemeType } from '@app/app.service';
import { ShellComponent } from '@app/shell/shell.component';
import { MsalService } from '@azure/msal-angular';
import { BrowserAuthError } from '@azure/msal-browser';
import { resetStores } from '@datorama/akita';
import { environment } from '@env/environment';
import { map, tap } from 'rxjs/operators';
import { Logger, UserService, UserSettingsQuery, initialScopes } from 'timeghost-api';
import { FeedbackDialogComponent } from '../dialogs/feedback-dialog/feedback-dialog.component';
import { UserAvatarComponent } from '../user-avatar/user-avatar.component';
import { THEME_ICON_MAP } from './theme-icons';
const log = new Logger('UserMenuComponent');
@Component({
  selector: 'app-user-menu',
  templateUrl: './user-menu.component.html',
  styleUrls: ['./user-menu.component.scss'],
})
export class UserMenuComponent implements OnInit {
  get currentWorkspace() {
    return this.userSettings.workspace;
  }
  @ViewChild(UserAvatarComponent, { read: MatMenuTrigger, static: true })
  menuTrigger: MatMenuTrigger;
  @Input()
  disableMenu: boolean = false;
  @Input()
  closeOnNavigation: boolean = false;

  constructor(
    private msalService: MsalService,
    private userSettingsQuery: UserSettingsQuery,
    private userService: UserService,
    private appService: AppService,
    private router: Router,
    @Optional()
    private shell: ShellComponent,
    private dialog: MatDialog,
  ) {}
  closeShell() {
    if (this.shell?.isMobile && this.shell.sidenav?.mode === 'over') {
      this.shell.sidenav.close(), this.menuTrigger?.closeMenu?.();
    }
  }
  status$ = this.userSettingsQuery.select().pipe(
    map((x) => {
      return parseSubscriptionAsStatus(x.workspace, x);
    }),
  );
  userSettings$ = this.userSettingsQuery.select();
  readonly name$ = this.userSettings$.pipe(
    map((x) => x?.officeProfile?.preferredName ?? x?.officeProfile?.displayName),
    map((name) => name?.split('|', 2)[0]?.trimEnd()),
  );
  readonly userTitle$ = this.userSettings$.pipe(
    map((user) => {
      const titleMap: Record<string, string | { match: RegExp; value: string }> = {
        'timeghost.io': 'Timeghost',
        'sharepoint-template.com': 'Timeghost',
        demoUser: {
          match: /^m365(.*).onmicrosoft.(\w+)$/,
          value: 'Microsoft Demo-/Test-User',
        },
      };
      const mailPart = user.email?.split('@', 2)[1]?.toLowerCase();
      if (!mailPart) return null;
      const [, returnValue] =
        Object.entries(titleMap).find(([key, x]: any) => {
          if (typeof x === 'object') return x.match.test(mailPart);
          return key === mailPart;
        }) || [];
      if (typeof returnValue === 'object') return returnValue.value;
      return returnValue;
    }),
    tap((x) => log.debug('user title: ', x)),
  );
  get isExpired() {
    return parseSubscriptionAsStatus(this.userSettings.workspace, this.userSettings).isExpired;
  }
  get isTrial() {
    return parseSubscriptionAsStatus(this.userSettings.workspace, this.userSettings).isTrial;
  }
  navigateWorkspace(commands: any[], extras?: NavigationExtras) {
    return this.router.navigate(['settings', 'workspace', ...commands], extras);
  }
  ngOnInit() {}
  async logout() {
    if (this.appService.isTeams()) {
      localStorage.clear(), sessionStorage.clear();
      localStorage.setItem('teams_auth_scopes', initialScopes.join(','));
      localStorage.setItem('teams_auth_clientId', environment.adalConfig.clientId);
      await this.msalService.instance
        .logoutPopup({ account: this.msalService.instance.getActiveAccount() })
        .catch((err) => {
          if (err instanceof BrowserAuthError && err.errorCode === 'popup_window_error') {
            return this.msalService.instance.logoutRedirect({ account: this.msalService.instance.getActiveAccount() });
          }
        });
      this.appService.resetStores();
      window.location.href = `${window.location.origin}/auth/teams/login`;
      return;
    }

    resetStores();
    this.msalService.logout();
  }
  readonly selectedTheme$ = fromRxValueWithState(({ setLoading }) =>
    this.appService.selectedTheme$.asObservable().pipe(
      map((theme) => {
        const icon = THEME_ICON_MAP[theme];
        return {
          icon,
          value: theme,
        };
      }),
    ),
  );
  get selectedTheme() {
    return this.appService.selectedTheme;
  }
  async setTheme(mode: ThemeType) {
    this.selectedTheme$.setLoading(true);
    await this.appService.setMode(mode);
    this.selectedTheme$.setLoading(false);
  }
  openFeedback() {
    return this.dialog.open(FeedbackDialogComponent);
  }
  openCurrentWorkspaceCheckout() {
    window.dataLayer.push({
      purchase_c2a: 'user-menu button',
    });

    return this.router.navigate(['/settings/workspace/plans']);
  }
  get userSettings() {
    return this.userSettingsQuery.getValue();
  }
  get packageVersion() {
    return environment.version;
  }
  get releaseName() {
    return environment.releaseName;
  }
  get isBeta() {
    return environment.isBeta;
  }
  get isdev() {
    return !environment.production;
  }
  @Input()
  size: string = '42px';
  get isDarkMode(): boolean {
    if (this.appService.selectedTheme === 'default') {
      if (window.matchMedia('(prefers-color-scheme: dark)').matches) {
        return true;
      }
    }
    return this.appService.selectedTheme === 'dark';
  }
  async toggleDarkMode() {
    const isDark = this.isDarkMode;
    return await this.appService.setMode(isDark ? 'light' : 'dark');
  }
}
